import React, { Suspense, lazy } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

const Multiply = lazy(() => import("containers/Multiply"));
const Pills = lazy(() => import("containers/Pills"));
const ReadingJump = lazy(() => import("containers/ReadingJump"));
const ReadingHurricane = lazy(() => import("containers/ReadingHurricane"));
const ReadingTest = lazy(() => import("containers/ReadingTest"));
const Tournament = lazy(() => import("pages/Tournament"));
const TournamentPills = lazy(() => import('./pages/TournamentPills'));

const App = () => {
  return (
    <BrowserRouter basename="/app">
      <Suspense fallback={<div>Loading...</div>}>
        <Switch>
          <Route path="/pills/:taskMode?/:day?" component={Pills}/>
          <Route
            path="/anzane_two/:taskMode?/:day?"
            component={Multiply}/>
          <Route
            path="/reading_jump/:taskMode?/:day?"
            component={ReadingJump}/>
          <Route
            path="/reading_hurricane/:taskMode?/:day?"
            component={ReadingHurricane}/>
          <Route
            path="/reading_test/:taskMode?/:day?"
            component={ReadingTest}/>
          <Route
            exact={true}
            path="/tournament/anzane_two/:tournamentId/:tourType/:tourNumber"
            component={Tournament}/>
          <Route
            exact={true}
            path="/tournament/pills/:tournamentId/:tourType/:tourNumber"
            component={TournamentPills}/>
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
};

export default App;
